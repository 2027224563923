import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { ACCOUNT_URL, MANAGE_ORDERS_URL, NOTIFICATION_URL, ORDERS_DETAIL_URL, OUTSTANDING_ORDERS_URL, PRODUCT_URL, RECIEPT_URL,WELCOME_URL } from "../_metronic/_partials/constant/route";
import Information from "./modules/Information/page/Information";
import { OrderDetail } from "./modules/OrderDetail/OrderDetails";
import { ManageOrderPage } from "./modules/ManageOrder/pages/manageOrderPage";
import OutStandingOrder from "./modules/OutstandingOrder/page/OutstandingOrder";
import PaymentList from "./modules/Payment/page/PaymentList";
import PaymentDetail from "./modules/Payment/page/PaymentDetail";
import ChangePassword from "./modules/Information/page/change-password/ChangePassword";
import NotificationBase from "./modules/Notification/pages/Notification";
import ProductBase from "./modules/Product/pages/ProductBase";
import WelcomeToAmacSports from "./modules/Welcome/Welcome";


export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to={MANAGE_ORDERS_URL.BASE_URL} />
        }
        <ContentRoute path={MANAGE_ORDERS_URL.BASE_URL} exact={true} component={ManageOrderPage} />
        <ContentRoute path={OUTSTANDING_ORDERS_URL.BASE_URL} exact={true} component={OutStandingOrder} />
        <ContentRoute path={`${ORDERS_DETAIL_URL}/:id`} exact={true} component={OrderDetail} />
        <ContentRoute path={RECIEPT_URL.BASE_URL} exact={true} component={PaymentList} />
        <ContentRoute path={RECIEPT_URL.BASE_URL+"/:id"} exact={true} component={PaymentDetail} />
        <ContentRoute path={ACCOUNT_URL.BASE_URL} exact={true} component={Information} />
        <ContentRoute path={ACCOUNT_URL.CHANGE_PASSWORD} exact={true} component={ChangePassword} />
        <ContentRoute path={NOTIFICATION_URL.BASE_URL} exact={true} component={NotificationBase} />
        <ContentRoute path={PRODUCT_URL.BASE_URL} exact={true} component={ProductBase} />
        <ContentRoute path={WELCOME_URL.BASE_URL} exact={true} component={WelcomeToAmacSports} />
        <Redirect to="error/error-v2" />
      </Switch>
    </Suspense>
  );
}
