import React, { useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination'
import RemoveIcon from '@material-ui/icons/Remove';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { ORDERS_DETAIL_URL } from '../../../../../../_metronic/_partials/constant/route';
import { useHistory } from 'react-router';
import { DebounceInput } from 'react-debounce-input';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate, formatDateFilter } from '../../../../ManageOrder/partials/formatDate';
import { defaultInputRanges, defaultStaticRanges } from '../../../../ManageOrder/partials/dateRange/defaultRange';
import Select from 'react-select';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { ModalConfirm } from '../../../../../../_metronic/_partials/controls';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Button } from 'react-bootstrap';
const options = [
    { value: 1, label: 'Ngày giao giảm dần' },
    { value: 0, label: 'Ngày đặt giảm dần' },
];
const expandRow = {
    renderer: row => {
        return <React.Fragment>
            <Grid container>
                <p className="mr-3 font-weight-600">Sản phẩm:</p>
                <p>{row.name}</p>
            </Grid>
            <Grid container>
                <span className="mr-3 font-weight-600">Trạng thái:</span>
                <span>{checkStatusOrder(row.status)}</span>
            </Grid>
        </React.Fragment>
    }
};
const checkStatusOrder = (status) => {
    switch (status) {
        case 0:
            return '';
        case 1:
            return 'Hoàn thành';
        default:
            return 'Hủy';
    }
}
const OneItemExpand = ({ cell }) => {
    const history = useHistory()
    const [isExpand, setExpand] = useState(false)
    return <React.Fragment>
        {isExpand ? <span className="svg-icon menu-icon">
            <RemoveIcon className="text-danger size-18 mr-3" onClick={() => setExpand(!isExpand)} />
        </span> :
            <AddCircleOutlineIcon className="green-primary size-18 mr-3" onClick={() => setExpand(!isExpand)} />}
        <span className="green-primary font-weight-bold"
            onClick={() => history.push(`${ORDERS_DETAIL_URL}/${cell}`)}
        >{cell}</span>
    </React.Fragment>
}
const MobileViewOutStandingOrder = ({
    debtOrderEntities,
    totalCount,
    debtTotal,
    setPage,
    page,
    handleSearch,
    removeFormRange,
    dateRangeStatus,
    setDateRangeStatus,
    dateState,
    setDateState,
    setDateStart,
    setDateEnd,
    setOrder
}) => {
    const [isShowFilter, setShowFilter] = useState(false);
    const columns = [{
        dataField: 'id',
        text: 'Mã đơn',
        formatter: (cell, row) => {
            return <OneItemExpand cell={cell} />
        }
    }, {
        dataField: 'orderTotalAmount',
        text: 'Tiền đơn hàng',
        formatter: (cell, row) => {
            return new Intl.NumberFormat().format(cell) + " đ"
        }
    }
        , {
        dataField: 'received',
        text: 'Đã thanh toán',
        formatter: (cell, row) => {
            return new Intl.NumberFormat().format(cell) + " đ"
        }
    }
        , {
        dataField: 'amount',
        text: 'Còn lại',
        formatter: (cell, row) => {
            let value = row.orderTotalAmount - row.received
            return new Intl.NumberFormat().format(value) + " đ"
        }
    }
    ];

    return <React.Fragment>
        <Grid container className='bg-white outstanding-container rounded font-roboto'>
            <Grid className='wrap-title'>
                <p className='size-20 dark-primary font-weight-900 font-slab'>Danh sách đơn hàng chưa thanh toán</p>
                <p className='size-14 text-subtitle'>Hiện có {totalCount} đơn hàng chưa thanh toán với tổng tiền là {new Intl.NumberFormat().format(debtTotal) + " đ"}</p>
            </Grid>
            <Grid container className="d-flex justify-content-between mt-3 align-items-center px-2">
                <Grid style={{ width: '85%' }}>
                    <DebounceInput
                        className='my-input pl-3 w-100'
                        placeholder='Nhập vào mã đơn, tên sản phẩm...'
                        minLength={1}
                        debounceTimeout={600}
                        onChange={handleSearch} />
                </Grid>
                <div className='p-2 rounded' style={{ backgroundColor: '#A5D3A5' }} onClick={() => setShowFilter(!isShowFilter)}><SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} /></div>
            </Grid>
            <Grid container className="mt-7 size-14">
                {debtOrderEntities.length > 0 ? <BootstrapTable
                    loading={true}
                    keyField='id'
                    data={debtOrderEntities}
                    columns={columns}
                    expandRow={expandRow}

                />
                    : <span className="w-100 text-center size-14">Không tìm thấy kết quả</span>}
            </Grid>
            <Grid className="w-100 d-flex justify-content-end wrap-total mb-5">
                <span className='font-weight-700 size-16 mr-5'>Còn lại:</span>
                <span className='font-weight-700 size-16 text-price'>{new Intl.NumberFormat().format(debtTotal) + " đ"}</span>
            </Grid>
            <Grid className="responsive-navigation w-100">
                <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
            </Grid>
        </Grid>
        <ModalConfirm
            show={isShowFilter}
            setVisible={setShowFilter}
            title="Lọc danh sách đơn hàng"
            MyForm={
                <React.Fragment>
                    <Grid container>
                        <p className='align-self-center font-weight-bolder mt-5'>Ngày đặt</p>
                        <Grid item xs={12} className='position-relative'>
                            <DateRangeIcon className='position-absolute mt-2 ml-2 color-gray-dark' onClick={() => setDateRangeStatus(!dateRangeStatus)} />
                            <input className='my-input w-100 pl-10 letter-spacing' onClick={() => setDateRangeStatus(!dateRangeStatus)}
                                value={
                                    (dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ?
                                        formatDate(dateState.datefilter.startDate) + '-' + formatDate(dateState.datefilter.endDate) : ''}
                                readOnly
                            />
                            {(dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ? <HighlightOffIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => removeFormRange()} /> : <ExpandMoreIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => setDateRangeStatus(!dateRangeStatus)} />}
                        </Grid>
                        {dateRangeStatus && <Grid item xs={12} md={9} className='position-relative mt-3'>
                            <DateRangePicker
                                className='date-range-mobile'
                                onChange={item => {
                                    setDateStart(formatDateFilter(item.datefilter.startDate))
                                    setDateEnd(formatDateFilter(item.datefilter.endDate))
                                    setPage(1)
                                    setDateState({ ...dateState, ...item })
                                    setDateRangeStatus(!dateRangeStatus)
                                }
                                }
                                ranges={[dateState.datefilter]}
                                locale={locales.vi}
                                staticRanges={defaultStaticRanges}
                                inputRanges={defaultInputRanges}
                            />
                        </Grid>}
                    </Grid>
                    <Grid className="w-100">
                        <p className='align-self-center font-weight-bolder mt-5'>Lọc theo ngày</p>
                        <Select
                            className="my-select2"
                            placeholder="Ngày giao giảm dần"
                            options={options}
                            onChange={option => setOrder(option.value)}
                        />
                    </Grid>
                    <Grid className="w-100 d-flex justify-content-center mt-7">
                        <Button variant="primary" onClick={() => setShowFilter(!isShowFilter)}>Lọc</Button>
                    </Grid>
                </React.Fragment>
            }
        />
    </React.Fragment>
}
export default MobileViewOutStandingOrder;