import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    orderEntities: [],
    orderForEdit: undefined,
    lastError: null,
    statistics: null
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const OrderSlice = createSlice({
    name: "order",
    initialState: initialStates,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        orderListFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.orderEntities = action.payload.orderEntities;
            state.totalCount = action.payload.totalCount;
            state.error = null;
        },
        orderFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.orderForEdit = action.payload.orderForEdit;
            state.error = null;
        },
        statisticFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.statistics = action.payload.statistics;
            state.error = null;
        },
    }
})