import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../_redux/paymentAction";
import { useHistory } from 'react-router';
import {  ORDERS_DETAIL_URL } from '../../../../_metronic/_partials/constant/route';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
const PaymentDetail = (props) => {
    const id  = props.match.params.id;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(()=>{
        document.title = 'Danh sách hoá đơn';
        dispatch(actions.fecthPayment(id));
    },[dispatch,id]);
    const {payment} = useSelector((state) =>({
        payment: state.payment.payment,
    }));
    const calculateTotal = (value) => {
        return new Intl.NumberFormat().format(value) + " đ"
    }
    const methodPayment = (method) => {
            switch(method){
                case 0:
                    return 'Tiền mặt';
                case 1: 
                    return 'Thẻ';
                default:
                    return 'Chuyển khoản';   
            }
    }
    const totalPayment = (paymentDetails) =>{
        let sum = 0;
        paymentDetails.forEach((item)=>{
            sum+=item.received;
        });

        return calculateTotal(sum);
    }

    return <React.Fragment>
         <KeyboardBackspaceIcon 
          onClick={()=>{
            history.goBack()
        }}
         style={{ fontSize: 30, background: '#fff', marginBottom: 10, marginTop: 10,width:50,borderRadius:10 }} />
        <Grid container className='bg-white py-10 px-15 rounded font-roboto'>
            
                {payment && (
                    <Grid>
                    <p className='size-20 dark-primary font-weight-900 font-slab'>Hóa đơn {id}</p>
                    <p className='size-10 dark-primary font-weight-900 font-slab'>Ngày tạo : {payment.createdAt}</p>
                    <p className='size-10 dark-primary font-weight-900 font-slab'>Phương thức thanh toán: {methodPayment(payment.method)} </p>
                    <p className='size-10 dark-primary font-weight-900 font-slab'>Tổng thanh toán: {totalPayment(payment.paymentDetails)} </p>
                    </Grid>
                )}
               
            
            <Grid container className="mt-7">
                <table className="table table-hover my-table">
                    <thead>
                        <tr className="dark-gray">
                            <th scope="col">Mã đơn</th>
                            <th scope="col">Tên đơn</th>
                            <th scope="col">Đã thanh toán</th>
                            <th scope="col">Thời gian tạo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payment && payment.paymentDetails.map((item, index) => (
                            <tr key={item.id}>
                                <th scope="row" className="py-5 pb-10 size-14 green-primary"><span className="cursor-pointer"
                                onClick={()=>{
                                    history.push(ORDERS_DETAIL_URL+"/"+item.order_id)
                                }}
                                >{item.order_id}</span></th>
                                <td className="py-5 pb-10 size-14">{item.order_name}</td>
                                <td className="py-5 pb-10 size-14">{calculateTotal(item.received)}</td>
                                <td className="py-5 pb-10 size-14">{item.createdAt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
            
        </Grid>
    </React.Fragment>
}
export default PaymentDetail;