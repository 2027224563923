import React, { useState, useEffect, useMemo } from 'react'
import './style.scss'
import * as debtActions from '../_redux/debtOrderAction'
import { useDispatch, useSelector } from 'react-redux';
import { DebtOrderLoading } from './loading/DebtOrderLoading';
import BrowserViewOutStandingOrder from './view-case/browser/BrowserViewOutStandingOrder';
import { BrowserView, MobileView } from "react-device-detect";
import MobileViewOutStandingOrder from './view-case/mobile/MobileViewOutStandingOrder';

const OutStandingOrder = () => {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({})
    const [page, setPage] = useState(1)
    const [dateStart, setDateStart] = useState(null)
    const [dateEnd, setDateEnd] = useState(null)
    const [order, setOrder] = useState(null)
    const [search, setSearch] = useState(null)
    const [dateRangeStatus, setDateRangeStatus] = useState()
    const [dateState, setDateState] = useState(
        {
            datefilter: {
                startDate: null,
                endDate: null,
                key: 'datefilter'
            },
        }
    );
    useMemo(() => setFilter({ ...filter, page, createdAtFrom: dateStart, createdAtTo: dateEnd, order, search }),
        [page, dateStart, dateEnd, order, search]
    )
    const handleSearch = (e) => {
        let { value } = e.target
        if (value.length === 0) {
            setSearch(null)
        } else {
            setSearch(value.trim())
            setPage(1)
        }
    }
    const removeFormRange = () => {
        setDateRangeStatus(false)
        setDateStart(null)
        setDateEnd(null)
        setDateState(
            {
                ...dateState, datefilter: {
                    startDate: null,
                    endDate: null,
                    key: 'datefilter'
                }
            }
        )
    }
    useEffect(() => {
        dispatch(debtActions.fetchDebtOrder(filter))
    }, [filter, dispatch])

    const { debtOrderEntities, totalCount, debtTotal } = useSelector(
        (state) => ({
            debtOrderEntities: state.debtOrder.debtOrderEntities,
            totalCount: state.debtOrder.totalCount,
            debtTotal: state.debtOrder.debtTotal,
        })
    )
    const calculatePrice = (recieved, totalAmount) => {
        const result = totalAmount - recieved
        return new Intl.NumberFormat().format(result) + " đ"
    }
    useEffect(() => {
        document.title = 'Đơn hàng chưa thanh toán'
    }, [])
    return <React.Fragment>
        <DebtOrderLoading />
        {debtOrderEntities && <React.Fragment>
            <BrowserView>
                <BrowserViewOutStandingOrder
                    debtOrderEntities={debtOrderEntities}
                    totalCount={totalCount}
                    debtTotal={debtTotal}
                    calculatePrice={calculatePrice}
                    setPage={setPage}
                    page={page}
                    handleSearch={handleSearch}
                    removeFormRange={removeFormRange}
                    dateRangeStatus={dateRangeStatus}
                    setDateRangeStatus={setDateRangeStatus}
                    dateState={dateState}
                    setDateState={setDateState}
                    setDateStart={setDateStart}
                    setDateEnd={setDateEnd}
                    setOrder={setOrder}
                />
            </BrowserView>
            <MobileView>
                <MobileViewOutStandingOrder
                    debtOrderEntities={debtOrderEntities}
                    totalCount={totalCount}
                    debtTotal={debtTotal}
                    setPage={setPage}
                    page={page}
                    handleSearch={handleSearch}
                    removeFormRange={removeFormRange}
                    dateRangeStatus={dateRangeStatus}
                    setDateRangeStatus={setDateRangeStatus}
                    dateState={dateState}
                    setDateState={setDateState}
                    setDateStart={setDateStart}
                    setDateEnd={setDateEnd}
                    setOrder={setOrder}
                />
            </MobileView>
        </React.Fragment>}
    </React.Fragment>
}

export default OutStandingOrder