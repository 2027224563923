import { Grid } from '@material-ui/core'
import React from 'react'
const OrderTracking = ({ assignments }) => {
   
    function _taskName(item){
       switch(item.state){
           case 0: 
                return 'Chờ '+item.task.toLowerCase();
            case 1:
                return 'Đang '+item.task.toLowerCase();
            default:
                return 'Hoàn tất '+item.task.toLowerCase();
       }
    }
    return <React.Fragment>
        <Grid>
            <div className="parent position-relative pl-5">
                {assignments.map((item, index) => {
                    let isActive = ""
                    if (0 === index) {
                        isActive = "active"
                    }
                
                    return < div className={"tracking-wrapper position-relative pt-3"} key={index} >
                        <p className="title-status">{_taskName(item)}</p>
                        <p className="time">{item.time.time + ", " + item.time.date}</p>
                        <span className={"point position-absolute " + isActive}></span>
                    </div>
                }
                )
                }
            </div>
        </Grid>
    </React.Fragment >
}

export default OrderTracking
