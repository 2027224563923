import * as requestFromServer from './debtOrderCrud';
import { DebtOrderSlice, callTypes } from './debtOrderSlice'
const { actions } = DebtOrderSlice;

export const fetchDebtOrder = ({ page, createdAtFrom, createdAtTo, order, search }) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getDebtOrdersList(({ page, createdAtFrom, createdAtTo, order, search }))
        .then(response => {
            const { status } = response
            if (status === 200) {
                const debtOrderEntities = response.data.data
                const totalCount = response.data.total
                const debtTotal = response.data.debt
                dispatch(actions.debtOrderListFetched({ debtOrderEntities, totalCount, debtTotal }))
            }
        }
        )
}
