import axios from "axios";

export const LOGIN_URL = process.env.REACT_APP_PUBLIC_URL + "login_check"
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = process.env.REACT_APP_PUBLIC_URL + "api/reset-password";
export const REFRESH_TOKEN = process.env.REACT_APP_PUBLIC_URL + "token/refresh";

export const ME_URL = process.env.REACT_APP_PUBLIC_URL + "api/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPasswordStepOne(email) {
  return axios.post(REQUEST_PASSWORD_URL + "/send", { email });
}
export function requestPasswordStepTwo(email, code) {
  return axios.post(REQUEST_PASSWORD_URL + "/verify", { email, code });
}
export function requestPasswordStepThree(email, code, password) {
  return axios.put(REQUEST_PASSWORD_URL + "/change", { email, code, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
export function refreshToken(refresh_token) {
  return axios.post(REFRESH_TOKEN, { refresh_token });
}
