import React from 'react';
import { Grid } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';
const VerifyPassword = () => {
    return <React.Fragment>
        <Grid className="reset-container step-3 w-100">
            <p className="title-reset mb-10 w-100 text-center">Mật khẩu mới</p>
            <Grid className="w-100 ">
                <p className='font-weight-bolder'>Mật khẩu mới</p>
                <Field className="form-control size-16 w-100" name="password" type="password" required />
                <ErrorMessage component='div' name="password" className="text-danger mt-3" />
            </Grid>
            <Grid className="w-100 mt-5 mb-10">
                <p className='font-weight-bolder'>Nhập lại mật khẩu mới</p>
                <Field className="form-control size-16 w-100" name="retypePassword" type="password" required />
                <ErrorMessage component='div' name="retypePassword" className="text-danger mt-3" />
            </Grid>
            <div className="w-100 d-flex justify-content-center">
                <button className="btn-reset font-weight-700" type="submit" >Đổi mật khẩu</button>
            </div>
        </Grid>
    </React.Fragment>
}
export default VerifyPassword