import { Modal } from "react-bootstrap"
import React from 'react'
export const ModalConfirm = ({ show, title, MyForm, setVisible, size }) => {
    return <Modal
        className="modal-confirm"
        show={show}
        footer={null}
        onHide={() => { setVisible(false) }}
        centered
        size={size}
    >
        <Modal.Header closeButton>
            <Modal.Title>
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {MyForm}
        </Modal.Body>
    </Modal>
}