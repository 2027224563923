import React from 'react'
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination'
import { useHistory } from 'react-router-dom';
import { ORDERS_DETAIL_URL } from '../../../../../../_metronic/_partials/constant/route';
import { defaultInputRanges, defaultStaticRanges } from '../../../../ManageOrder/partials/dateRange/defaultRange';
import Select from 'react-select';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DebounceInput } from 'react-debounce-input';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate, formatDateFilter } from '../../../../ManageOrder/partials/formatDate';
const options = [
    { value: 1, label: 'Ngày giao giảm dần' },
    { value: 0, label: 'Ngày đặt giảm dần' },
];
const BrowserViewOutStandingOrder = ({
    debtOrderEntities,
    totalCount,
    debtTotal,
    calculatePrice,
    setPage,
    page,
    handleSearch,
    removeFormRange,
    dateRangeStatus,
    setDateRangeStatus,
    dateState,
    setDateState,
    setDateStart,
    setDateEnd,
    setOrder,
}) => {
    const history = useHistory()
    return <React.Fragment>
        <Grid container className='bg-white outstanding-container rounded font-roboto'>
            <Grid className='wrap-title'>
                <p className='size-20 dark-primary font-weight-900 font-slab'>Danh sách đơn hàng chưa thanh toán</p>
                <p className='size-14 text-subtitle'>Hiện có {totalCount} đơn hàng chưa thanh toán với tổng tiền là {new Intl.NumberFormat().format(debtTotal) + " đ"}</p>
            </Grid>
            <Grid className="w-100">
                <Grid container className='mt-7 px-5 d-flex mr-2'>
                    <Grid item md={4} lg={3}>
                        <div className='position-relative w-100'>
                            <i className="fa fa-search icon position-absolute mt-3 ml-3" />
                            <DebounceInput
                                className='my-input pl-10 w-100'
                                placeholder='Nhập vào mã đơn, tên sản phẩm...'
                                minLength={1}
                                debounceTimeout={600}
                                onChange={handleSearch} />
                        </div>
                    </Grid>
                    <Grid item md={4} lg={4} container className='d-flex justify-content-end'>
                        <span className='align-self-center font-weight-bolder mr-3'>Ngày đặt</span>
                        <Grid item md={8} className='position-relative'>
                            <Grid className='position-relative'>
                                <DateRangeIcon className='position-absolute mt-2 ml-2 color-gray-dark cursor-pointer' onClick={() => setDateRangeStatus(!dateRangeStatus)} />
                                <input className='my-input w-100 pl-10 letter-spacing cursor-pointer' onClick={() => setDateRangeStatus(!dateRangeStatus)}
                                    value={
                                        (dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ?
                                            formatDate(dateState.datefilter.startDate) + '-' + formatDate(dateState.datefilter.endDate) : ''}
                                    readOnly
                                />
                                {(dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ? <HighlightOffIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => removeFormRange()} /> : <ExpandMoreIcon className="position-absolute mt-2 clear-form cursor-pointer" style={{ right: "3%" }} onClick={() => setDateRangeStatus(!dateRangeStatus)} />}
                            </Grid>
                        </Grid>
                        {dateRangeStatus && <Grid item md={9} className='position-relative mt-3'>
                            <DateRangePicker
                                onChange={item => {
                                    setDateStart(formatDateFilter(item.datefilter.startDate))
                                    setDateEnd(formatDateFilter(item.datefilter.endDate))
                                    setPage(1)
                                    setDateState({ ...dateState, ...item })
                                }
                                }
                                ranges={[dateState.datefilter]}
                                locale={locales.vi}
                                staticRanges={defaultStaticRanges}
                                inputRanges={defaultInputRanges}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid className="mt-7 d-flex justify-content-end  px-5" container>
                    <span className='align-self-center mr-3'>Sắp xếp theo</span>
                    <Grid item md={2}>
                        <Select
                            className="my-select2"
                            placeholder="Ngày giao giảm dần"
                            options={options}
                            onChange={option => setOrder(option.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="mt-7">
                {debtOrderEntities.length > 0 ? <table className="table table-hover my-table">
                    <thead>
                        <tr className="dark-gray">
                            <th scope="col">Mã đơn</th>
                            <th scope="col">Sản phẩm</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col">Tiền đơn hàng</th>
                            <th scope="col">Đã thanh toán</th>
                            <th scope="col">Còn lại</th>
                        </tr>
                    </thead>
                    <tbody>
                        {debtOrderEntities.map((item, index) => (
                            <tr key={index}>
                                <th scope="row" className="py-5 pb-10 size-14 green-primary"><span className="cursor-pointer"
                                    onClick={() => history.push(`${ORDERS_DETAIL_URL}/${item.id}`)}
                                >{item.id}</span></th>
                                <td className="py-5 pb-10 size-14">{item.name}</td>
                                <td className="py-5 pb-10 size-14">Đang sản xuất</td>
                                <td className="py-5 pb-10 size-14">
                                    {new Intl.NumberFormat().format(item.orderTotalAmount) + " đ"}
                                </td>
                                <td className="py-5 pb-10 size-14">{new Intl.NumberFormat().format(item.received) + " đ"}</td>
                                <td className="py-5 pb-10 size-14">{calculatePrice(item.received, item.orderTotalAmount)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> : <span className="w-100 text-center size-14">Không tìm thấy kết quả</span>}
            </Grid>
            <Grid className="w-100 d-flex justify-content-end wrap-total mb-5">
                <span className='font-weight-700 size-18 mr-5'>Còn lại:</span>
                <span className='font-weight-700 size-18 text-price'>{new Intl.NumberFormat().format(debtTotal) + " đ"}</span>
            </Grid>
            <Grid className="responsive-navigation w-100">
                <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
            </Grid>
        </Grid>
    </React.Fragment>
}
export default BrowserViewOutStandingOrder