import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    pageSize: 0,
    productEntities: [],
    productForEdit: undefined,
    access_token: null,
    expires_in: 5000,
    token_type: "Bearer",
    lastError: null,
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const ProductSlice = createSlice({
    name: "product",
    initialState: initialStates,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        fetchTokenSuccess: (state, action) => {
            state.expires_in = action.payload.expires_in
            state.token_type = action.payload.token_type
            state.access_token = action.payload.access_token
            state.actionsLoading = false;
        },
        productListFetched: (state, action) => {
            state.listLoading = false;
            state.productEntities = action.payload.productEntities;
            state.totalCount = action.payload.totalCount;
            state.pageSize = action.payload.pageSize;
        }
    }
})