import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    provinceEntities: [],
    districtEntities: [],
    accountForEdit: undefined,
    lastError: null,
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const AccountSlice = createSlice({
    name: "account",
    initialState: initialStates,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        provinceListFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.provinceEntities = action.payload.provinceEntities;
            state.totalCount = action.payload.totalCount;
            state.error = null;
        },
        districtsFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.districtEntities = action.payload.districtEntities;
            state.error = null;
        },
        updateAccountSuccess: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },
    }
})