import _ from 'lodash';
import React from 'react';
export const formatSizeOfSex = (sizeObj) => {
    let sex = []
    let count = []
    let results = []
    Object.keys(_.groupBy(sizeObj, 'sex')).map((key) => {
        return sex.push(key)
    })
    Object.values(_.groupBy(sizeObj, 'sex')).map((val, index) => {
        let total = 0
        for (let i = 0; i < val.length; i++) {
            for (let j = 0; j < sex.length; j++) {
                if (val[i].sex === sex[j]) {
                    total += parseFloat(val[i].quantity)
                }
            }
        }
        return count.push(total)
    })
    for (let i = 0; i < sex.length; i++) {
        for (let j = 0; j < count.length; j++) {
            var obj = {}
            if (i === j) {
                obj['key'] = sex[i]
                obj['value'] = count[j]
                results.push(obj)
            }
        }
    }
    return results.map((item, index) => {
        return <span key={index}>{item.key} ({item.value}){(index === results.length - 1) ? "" : ", "}</span>
    })

}