import * as requestFromServer from './productCrud';
import { ProductSlice, callTypes } from './productSlice'
const { actions } = ProductSlice;

export const postKiotVietAuthentication = ({ client_id, client_secret, grant_type, scopes }) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .postAuthenticationKiotViet({ client_id, client_secret, grant_type, scopes })
        .then((response) => {
            const { status } = response;
            if (status === 200) {
                const { access_token, expires_in, token_type } = response
                dispatch(actions.fetchTokenSuccess({ access_token, expires_in, token_type }))
            }
        }).catch((err) => { console.log(err); })
}

export const getProductsList = ({ pageSize, includeInventory, categoryId }, { access_token, token_type }) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.getProductFromKiotViet({ pageSize, includeInventory, categoryId }, { access_token, token_type })
        .then((response) => {
            if (response.status === 200) {
                const productEntities = response.data
                const totalCount = response.total
                const pageSize = response.pageSize
                dispatch(actions.productListFetched({ productEntities, totalCount, pageSize }))
            }
        })
}