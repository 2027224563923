import * as requestFromServer from './accountCrud';
import { AccountSlice, callTypes } from './accountSlice'
import { SnackbarSlice } from '../../snackbar-alert/redux/snackBarSlice'
const actionsSnackbar = SnackbarSlice.actions
const { actions } = AccountSlice;

export const editAccountInfor = (payload) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .putProfile(payload)
        .then(response => {
            const { status } = response
            if (status === 200) {
                dispatch(actionsSnackbar.alertSuccess({ message: "Cập nhật thông tin thành công" }))
                dispatch(actions.updateAccountSuccess())
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Cập nhật thông tin thất bại" }))
            }
        }
        ).catch(error => {
            dispatch(actionsSnackbar.alertFailure({ message: "Cập nhật thông tin thất bại" }))
        })
}
export const getProvinces = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.getProvinces()
        .then(response => {
            const { status } = response
            if (status === 200) {
                const provinceEntities = response.data
                dispatch(actions.provinceListFetched({ provinceEntities }))
            }
        })
}
export const getDistricts = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.getDistricts(id)
        .then(response => {
            const { status } = response
            if (status === 200) {
                const districtEntities = response.data
                dispatch(actions.districtsFetched({ districtEntities }))
            }
        })
}
export const changePassword = (payload) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.actions }));
    return requestFromServer.putChangePassword(payload)
        .then(response => {
            const { status } = response
            if (status === 200) {
                dispatch(actionsSnackbar.alertSuccess({ message: 'Cập nhật mật khẩu thành công' }))
            }
        })
}
