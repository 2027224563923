import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        width: '100%',
        paddingLeft: '2rem !important',
    },
})(Tabs);
export const AntTab = withStyles((theme) => ({
    root: {
        fontSize:14,
        opacity: 1,
        textTransform: 'none',
        minWidth: 72,
        color: '#000000',
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(2),
        fontFamily: [
            'Roboto Slab',
            '"Helvetica Neue"',
        ].join(','),
        '&:hover': {
            color: '#489E13',
        },
        '&$selected': {
            color: '#489E13',
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:focus': {
            color: '#489E13',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);