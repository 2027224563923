import { createSlice } from "@reduxjs/toolkit";

const initialSnackbarState = {
    listLoading: false,
    actionsLoading: false,
    lastError: null,
    status: false,
    message: null,
    type: null,
};
export const callTypes = {
    list: "list",
    action: "action"
};
export const SnackbarSlice = createSlice({
    name: "snackbar",
    initialState: initialSnackbarState,
    reducers: {
        alertSuccess: (state, action) => {
            state.status = true;
            state.type = "success";
            state.message = action.payload.message;
        },
        alertFailure: (state, action) => {
            state.status = true;
            state.type = "error";
            state.message = action.payload.message;
        },
        alertWarning: (state, action) => {
            state.status = true;
            state.type = "warning";
            state.message = action.payload.message;
        },
        alertInfor: (state, action) => {
            state.status = true;
            state.type = "infor";
            state.message = action.payload.message;
        },
        alertOff: (state) => {
            state.status = false;
            state.type = "";
            state.message = "";
        }
    }
})