import React, { useState } from 'react';
import { Formik, Form, FieldArray } from "formik";
import VerifyEmail from './steps/VerifyEmail';
import VerifyCode from './steps/VerifyCode';
import * as auth from "../../_redux/authRedux";
import { connect, useDispatch } from "react-redux";
import VerifyPassword from './steps/VerifyPassword';
import { Grid } from '@material-ui/core';
import { requestPasswordStepOne, requestPasswordStepThree, requestPasswordStepTwo } from "../../_redux/authCrud";
import * as Yup from "yup";
import { SnackbarSlice } from '../../../snackbar-alert/redux/snackBarSlice'
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
const actionsSnackbar = SnackbarSlice.actions
const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Email không hợp lệ")
        .required("Vui lòng nhập email"),
    retypePassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Mật khẩu nhập lại không đúng"
    ),
    password: Yup.string().min(5, "Mật khẩu khẩu phải có ít nhất 6 kí tự")
});
const ResetPassword = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [resetPasswordStep, setPasswordStep] = useState(1)
    const [emailToResend, setEmailToResend] = useState("")
    const changeStep = () => {
        setPasswordStep(resetPasswordStep + 1)
    }
    const requestEmailStep = () => {
        requestPasswordStepOne(emailToResend).then((response) => {
            const { status } = response;
            if (status === 200) {
                dispatch(actionsSnackbar.alertSuccess({ message: "Gửi lại mã xác thực thành công" }))
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Gửi lại mã xác thực thất bại" }))
            }
        })
    }
    return <React.Fragment>
        <Formik
            initialValues={{
                email: "",
                code: "",
                password: "",
                retypePassword: "",
            }}
            enableReinitialize={true}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values) => {
                const { code, password, email } = values;
                setEmailToResend(email)
                if (!code && !password) {
                    requestPasswordStepOne(email).then((response) => {
                        const { status } = response;
                        if (status === 200) {
                            dispatch(actionsSnackbar.alertSuccess({ message: "Mã xác thực đã được gửi đến email của bạn " }))
                            changeStep()
                        }
                    })
                }
                else if (email && code && !password) {
                    requestPasswordStepTwo(email, code).then(response => {
                        const { status } = response;
                        if (status === 200 && response.data.data === "Code success") {
                            changeStep()
                        } else {
                            dispatch(actionsSnackbar.alertFailure({ message: "Mã xác thực không chính xác" }))
                        }
                    })
                }
                else if (email && code && password) {
                    requestPasswordStepThree(email, code, password).then(response => {
                        const { status } = response;
                        if (status === 200 && response.data.data === "Change success") {
                            dispatch(actionsSnackbar.alertSuccess({ message: "Cập nhật mật khẩu thành công" }))
                            setTimeout(function () { history.push("/auth/login") }, 1500);
                        } else {
                            dispatch(actionsSnackbar.alertFailure({ message: "Có lỗi xảy ra" }))
                        }
                    })
                }
            }}
        >
            {
                ({ setFieldValue }) => (
                    <FieldArray>
                        <Form>
                            <Grid className="font-roboto w-100">
                                <div className="w-100 logo-auth-style reset-banner">
                                    <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-customer.jpg")} className="logo-login" />
                                </div>
                                {(resetPasswordStep === 1) ?
                                    <VerifyEmail changeStep={changeStep} /> :
                                    (resetPasswordStep === 2) ?
                                        <VerifyCode changeStep={changeStep} setFieldValue={setFieldValue} requestEmailStep={requestEmailStep} /> :
                                        <VerifyPassword />}
                            </Grid>
                        </Form>
                    </FieldArray>
                )
            }
        </Formik>
    </React.Fragment>
}
export default connect(null, auth.actions)(ResetPassword)