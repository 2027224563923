import React from 'react';
import { Grid } from '@material-ui/core';
import './style.scss';
const WelcomeToAmacSports = () => {
    return <React.Fragment>
        <Grid style={{ height: '100vh' }} className="bg-light">
            
            <Grid className="main-content">
                <p className='dark-primary font-weight-700 font-slab size-30 w-100 text-center'>HƯỚNG DẪN SỬ DỤNG PHẦN MỀM </p>
                <Grid className="main-video" container>
                    <div className="video d-flex justify-content-end" >
                        <iframe src="https://www.youtube.com/embed/wqahfFJN7fM" frameborder="0" allowfullscreen title="video-explain"></iframe>
                    </div>
                    <div className="explain size-16">
                        <p className="font-weight-bold">Giới thiệu các trang của phần mềm:</p>
                        <p>Danh sách đơn hàng</p>
                        <p>Danh sách đơn hàng chưa thanh toán</p>
                        <p>Hóa đơn</p>
                        <p>Thông tin tài khoản</p>
                        <p className="font-weight-bold">Các tùy chọn nâng cao:</p>
                        <p>Xem danh sách theo trạng thái đơn hàng</p>
                        <p>Xem danh sách theo thời gian </p>
                        <p>Tìm kiếm, sắp xếp</p>
                        <p></p>
                    </div>
                </Grid>
            </Grid>
            <Grid className="footer">
                    <span className="size-16">© 2021 - Xưởng may thể thao <span className="green-primary">AMAC</span>.  Thực hiện bởi <span className="green-primary">CTASolution.com</span></span>
            </Grid>
        </Grid>
    </React.Fragment>
}

export default WelcomeToAmacSports