import axios from "axios";

const ORDER_URL = process.env.REACT_APP_PUBLIC_URL + "api/orders"
const STATISTICS_URL = process.env.REACT_APP_PUBLIC_URL + "api/orders/statistics"

export function getOrdersList({ page, status, code, createdAtFrom, createdAtTo, order, search }) {
    return axios.get(ORDER_URL
        , {
            params: {
                page: page,
                status: status,
                code: code,
                createdAtFrom: createdAtFrom,
                createdAtTo: createdAtTo,
                order: order,
                search: search,
            }
        }
    )
}

export function getOrderByID(id) {
    return axios.get(`${ORDER_URL}/${id}`)
}
export function getStatistics() {
    return axios.get(STATISTICS_URL)
}