import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";

export function DebtOrderLoading() {
    const { isLoading } = useSelector((state) => (
        { isLoading: state.debtOrder.listLoading }
    ));

    useEffect(() => { }, [isLoading]);
    return <LoadingDialog isLoading={isLoading} text="Loading...." />;
}