import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Update';
import DescriptionIcon from '@material-ui/icons/Description';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './style.scss'
import NotificationAll from './component/notification-all/NotificationAll';
import NotificationOrder from './component/notification-order/NotificationOrder';
import NotificationReturn from './component/notification-return/NotificationReturn';
import Pagination from '@material-ui/lab/Pagination'
const NotificationBase = () => {
    useEffect(() => {
        document.title = 'Thông báo'
    }, [])
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return <React.Fragment>
        <p className='size-30 black-primary font-weight-700'>Thông báo của tôi</p>
        <Grid>
            <Grid container className="bg-white rounded">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className="notification-tabs"
                >
                    <Tab label={<span className="notificate-active position-relative"><HomeIcon /></span>} value={1} />
                    <Tab label={<span className="position-relative"><DescriptionIcon /></span>} value={2} />
                    <Tab label={<span className="notificate-active position-relative"><UpdateIcon /></span>} value={3} />
                </Tabs>

                <Grid container style={{minHeight:500}}>
                    {value === 1 ? <NotificationAll /> : value === 2 ? <NotificationOrder /> : <NotificationReturn />}
                </Grid>
                <Grid className="px-5 mb-5">
                    <Pagination count={10} className='my-navigation' />
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment >
}

export default NotificationBase