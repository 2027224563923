import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { SnackbarSlice } from "../app/modules/snackbar-alert/redux/snackBarSlice";
import { OrderSlice } from "../app/modules/ManageOrder/_redux/order/orderSlice";
import { paymentSlice } from "../app/modules/Payment/_redux/paymentSlice";
import { DebtOrderSlice } from "../app/modules/OutstandingOrder/_redux/debtOrderSlice";
import { AccountSlice } from "../app/modules/Information/_redux/accountSlice";
import { ProductSlice } from "../app/modules/Product/_redux/productSlice";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  snackbar: SnackbarSlice.reducer,
  order: OrderSlice.reducer,
  payment: paymentSlice.reducer,
  debtOrder: DebtOrderSlice.reducer,
  account: AccountSlice.reducer,
  product: ProductSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
