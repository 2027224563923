import React from 'react';
import { Grid } from '@material-ui/core';
import { ErrorMessage, Field } from 'formik';
const VerifyEmail = () => {
    return <React.Fragment>
        <Grid className="reset-container w-100">
            <p className="title-reset">Quên mật khẩu?</p>
            <p className="text-reset size-18 mt-10">Nhập vào địa chỉ email để khổi phục mật khẩu:</p>
            <Field className="form-control size-16 mt-7" placeholder="Nhập email" name="email"
            />
            <ErrorMessage component='div' name="email" className="text-danger mt-3" />
            <button className="btn-reset font-weight-700 mt-7"
                type="submit"
            >Gửi</button>
        </Grid>
    </React.Fragment>
}
export default VerifyEmail