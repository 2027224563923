import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import ReactCodeInput from 'react-verification-code-input';
const VerifyCode = ({ setFieldValue, requestEmailStep }) => {
    const resendEmail = () => {
        requestEmailStep()
    }
    return <React.Fragment>
        <Grid className="reset-container w-100">
            <p className="title-reset w-100 text-center">Xác thực tài khoản</p>
            <p className="text-reset size-18 mt-10">Chúng tôi vừa gửi cho bạn mã xác nhận vui lòng điền vào ô bên dưới</p>
            <Field component={ReactCodeInput} className="size-16 my-7 my-verification-code" name="code" onComplete={val => setFieldValue('code', val)} required={true} />
            <p className='mb-5'>Nếu bạn không nhận được vui lòng nhấn <span className="text-resend font-weight-700 green-primary cursor-pointer ml-1"
                onClick={() => resendEmail()}
            >Gửi lại</span></p>
            <div className='d-flex justify-content-center wrap-code'><button className="btn-reset font-weight-700" type="submit">Xác thực</button></div>
        </Grid>
    </React.Fragment>
}
export default VerifyCode;