import { Grid } from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from "yup";
import { ACCOUNT_URL } from '../../../../_metronic/_partials/constant/route';
import './style.scss'
import * as accountActions from "../_redux/accountAction"
import { useDispatch, useSelector } from 'react-redux';
const EditAccountSchema = Yup.object().shape({
    name: Yup.string()
        .required('Tên danh mục không được bỏ trống'),
    province: Yup.string()
        .required('Chọn tỉnh thành'),
    district: Yup.string()
        .required('Chọn huyện'),
})
const Information = () => {
    const [province, setProvince] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(accountActions.getProvinces())
    }, [dispatch])
    useEffect(() => {
        if (province) {
            dispatch(accountActions.getDistricts(province))
        }
    }, [province, dispatch])
    const { provinces, districts, accountInfor } = useSelector(
        (state) => ({
            provinces: state.account.provinceEntities,
            districts: state.account.districtEntities,
            accountInfor: state.auth.user,
        }),
    )
    useEffect(() => {
        document.title = 'Thông tin tài khoản'
    }, [])
    return <React.Fragment>
        <Formik
            initialValues={{
                name: accountInfor.fullName,
                contact: accountInfor.contactPerson,
                address: accountInfor.address,
                province: province,
                district: '',
            }}
            enableReinitialize={true}
            validationSchema={EditAccountSchema}
            onSubmit={values => {
                dispatch(accountActions.editAccountInfor(values))
            }}
        >{({ handleChange, setFieldValue }) => (<Form>
            <Grid container className='bg-white rounded font-roboto information-container'>
                <p className='size-20 dark-primary font-weight-900 font-slab'>Thông tin tài khoản</p>
                <Grid container className='mt-7' spacing={3}>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Tên shop:</p>
                        <input className="my-input radius-10 dark-33" name="name" placeholder='Tên shop' onChange={handleChange} defaultValue={accountInfor.fullName} />
                        <ErrorMessage component="div" name="name" className="text-danger mt-3" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Liên hệ</p>
                        <input className="my-input radius-10 dark-33" name='contact' placeholder='Tên liên hệ' onChange={handleChange} defaultValue={accountInfor.contactPerson} />
                        <ErrorMessage component="div" name="contact" className="text-danger mt-3" />
                    </Grid>
                </Grid>
                <Grid container className=' mt-10' spacing={3}>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Số điện thoại</p>
                        <input className="my-input radius-10 dark-33 bg-light" placeholder='Số điện thoại' value={accountInfor.phone} readOnly style={{ cursor: "not-allowed" }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Email</p>
                        <input className="my-input radius-10 dark-33 bg-light" placeholder='email' readOnly value={accountInfor.email} style={{ cursor: "not-allowed" }} />
                    </Grid>
                </Grid>
                <Grid container className=' mt-10' spacing={3}>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Địa chỉ</p>
                        <input className="my-input radius-10 dark-33" name="address" placeholder='Địa chỉ' onChange={handleChange} defaultValue={accountInfor.address} />
                        <ErrorMessage component="div" name="address" className="text-danger mt-3" />
                    </Grid>
                </Grid>
                <Grid container className=' mt-10' spacing={3}>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Tỉnh/ Thành phố:</p>
                        <Select
                            className="my-select2 dark-33"
                            options={provinces.map(item => ({ value: item.id, label: item.name }))}
                            onChange={({ value }) => setProvince(value)}
                        />
                        <ErrorMessage component="div" name="province" className="text-danger mt-3" />
                    </Grid>
                </Grid>
                <Grid container className='mt-10' spacing={3}>
                    <Grid item xs={12} md={4}>
                        <p className='dark-primary font-weight-900 font-slab size-16'>Quận/huyện:</p>
                        <Select
                            className="my-select2 dark-33"
                            options={districts.map(item => ({ value: item.id, label: item.name }))}
                            onChange={({ value }) => setFieldValue('district', value)}
                        />
                        <ErrorMessage component="div" name="district" className="text-danger mt-3" />
                    </Grid>
                </Grid>
                <Grid container className='mt-10'>
                    <Grid className='mr-3'>
                        <button type="submit" className="my-button blue-primary size-18 font-weight-700 text-center" style={{ color: 'white' }}>Cập nhật</button>
                    </Grid>
                    <Grid>
                        <button type="button" className="my-button btn-password blue-primary size-18 font-weight-700 text-center" style={{ color: 'white' }}
                            onClick={() => history.push(ACCOUNT_URL.CHANGE_PASSWORD)}
                        >Đổi mật khẩu</button>
                    </Grid>
                </Grid>
            </Grid>
        </Form>)}
        </Formik>
    </React.Fragment>
}

export default Information;