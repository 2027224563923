import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from "yup";
import * as accountActions from "../../_redux/accountAction"
import { useDispatch } from 'react-redux';
const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
    .required("Mật khẩu không được để trống")
    .min(6,"Mật khẩu phải có ít nhất 6 ký tự"),
    retypePassword: Yup.string()
    .optional().oneOf([Yup.ref("newPassword")], "Mật khẩu nhập lại không đúng")
    .required("Mật khẩu không được để trống")
    .min(6,"Mật khẩu phải có ít nhất 6 ký tự"),
})
const ChangePassword = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        document.title = 'Cập nhật mật khẩu'
    },[])
    return <React.Fragment>
        <Formik
            initialValues={{
                password: '',
                newPassword: '',
                retypePassword: '',
            }}
            enableReinitialize={true}
            validationSchema={ChangePasswordSchema}
            onSubmit={values => {
                delete values.retypePassword
                dispatch(accountActions.changePassword(values))
            }}
        >
            {({ handleChange }) => (<Form>
                <Grid className="bg-white rounded font-roboto change-pass">
                    <p className='size-20 dark-primary font-weight-900 font-slab'>Thay đổi mật khẩu</p>
                    <Grid container className='mt-7' spacing={3}>
                        <Grid item xs={12} md={4}>
                            <p className='dark-primary font-weight-900 font-slab size-16'>Mật khẩu cũ</p>
                            <input className="my-input radius-10 dark-33" placeholder='Mật khẩu cũ..' type="password" name="password" onChange={handleChange} />
                            <ErrorMessage component="div" name="password" className="text-danger mt-3" />
                        </Grid>
                    </Grid>
                    <Grid container className='mt-7' spacing={3}>
                        <Grid item xs={12} md={4}>
                            <p className='dark-primary font-weight-900 font-slab size-16'>Mật khẩu mới</p>
                            <input className="my-input radius-10 dark-33" placeholder='Mật khẩu cũ..' type="password" name="newPassword" onChange={handleChange} />
                            <ErrorMessage component="div" name="newPassword" className="text-danger mt-3" />
                        </Grid>
                    </Grid>
                    <Grid container className='my-7' spacing={3}>
                        <Grid item xs={12} md={4}>
                            <p className='dark-primary font-weight-900 font-slab size-16'>Nhập lại mật khẩu</p>
                            <input className="my-input radius-10 dark-33" placeholder='Mật khẩu cũ..' type="password" name="retypePassword" onChange={handleChange} />
                            <ErrorMessage component="div" name="retypePassword" className="text-danger mt-3" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <button type="submit" className="my-button blue-primary size-18 font-weight-700 text-center" style={{ color: 'white' }}>Cập nhật</button>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    </React.Fragment>
}
export default ChangePassword;