import axios from "axios";

const DEBT_ORDER_URL = process.env.REACT_APP_PUBLIC_URL + "api/debt"

export function getDebtOrdersList({ page, createdAtFrom, createdAtTo, order, search }) {
    return axios.get(DEBT_ORDER_URL
        , {
            params: {
                page: page,
                createdAtFrom: createdAtFrom,
                createdAtTo: createdAtTo,
                order: order,
                search: search,
            }
        }
    )
}