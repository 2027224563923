import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DebounceInput } from 'react-debounce-input';
import './style.scss'
import Pagination from '@material-ui/lab/Pagination'
import * as productActions from "../_redux/productAction"
import { useDispatch, useSelector } from 'react-redux';
const ProductBase = () => {
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({ pageSize: 10, includeInventory: true, categoryId: 833170 })
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState(1)
    const handleSearch = (e) => {
        let { value } = e.target
        if (value.length === 0) {
            setSearch(null)
        } else {
            setSearch(value.trim())
            setPage(1)
        }
    }
    useMemo(() => setFilter({ ...filter, page, search }),
        [page, search]
    )
    const { expires_in, access_token, token_type, totalCount, productEntities } = useSelector(
        (state) => ({
            expires_in: state.product.expires_in,
            access_token: state.product.access_token,
            token_type: state.product.token_type,
            totalCount: state.product.totalCount,
            productEntities: state.product.productEntities,
        })
    )
    function accessTokenKiotViet() {
        const client_id = "27d54080-eb2f-441e-a2b2-bb89c03eef12"
        const client_secret = "8AF6842463F69AE9C4E98B79A2359227CB8E761B"
        const grant_type = "client_credentials"
        const scopes = "PublicApi.Access"
        dispatch(productActions.postKiotVietAuthentication({ client_id, client_secret, grant_type, scopes }))
    }
    setTimeout(() => {
        accessTokenKiotViet()
    }, expires_in);
    useEffect(() => {
        document.title = 'Danh sách sản phẩm'
    }, [])
    useEffect(() => {
        if (access_token)
            dispatch(productActions.getProductsList(filter, { access_token, token_type }))
    }, [access_token])
    function checkamountOnHand(inventories) {
        let onHand = 0;
        inventories.forEach(item => {
            onHand += parseInt(item.onHand);
        });
        return onHand
    }
    return <React.Fragment>
        <Grid className="w-100 product-container bg-white">
            <p className='size-20 dark-primary font-weight-900 font-slab mb-7'>Danh sách sản phẩm</p>
            <Grid item xs={6} md={4} lg={3} className='product-search'>
                <div className='position-relative w-100'>
                    <i className="fa fa-search icon position-absolute mt-3 ml-3" />
                    <DebounceInput
                        className='my-input pl-10 w-100'
                        placeholder='Nhập vào mã đơn, tên sản phẩm...'
                        minLength={1}
                        debounceTimeout={600}
                        onChange={handleSearch} />
                </div>
            </Grid>
            <Grid className="w-100 mt-7 d-flex" container>
                {
                    productEntities.map((item, index) => {
                        return <Grid item className={"one-item rounded size-14 "} key={index}>
                            {item.images.map((image, index) => {
                                return <img alt="" src={image} className="product-image" key={index} />
                            })}
                            <p className="name"><span>{item.fullName}</span></p>
                            <p className="amount-left">Tồn kho: <span>{checkamountOnHand(item.inventories)}</span></p>
                            <p className="price"><span className="font-weight-bold size-16">{new Intl.NumberFormat().format(item.basePrice)} đ</span></p>
                        </Grid>
                    })
                }
            </Grid>
            <Grid className="mt-7">
                <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
            </Grid>
        </Grid>
    </React.Fragment>
}
export default ProductBase;