import axios from "axios";

const KIOTVIET_AUTHENTICATION_URL = process.env.REACT_APP_PUBLIC_KIOTVIET_AUTHENTICATION_URL
const KIOTVIET_PRODUCT_URL = process.env.REACT_APP_PUBLIC_KIOTVIET_URL

export const postAuthenticationKiotViet = ({ client_id, client_secret, grant_type, scopes }) => {
    return axios.post(KIOTVIET_AUTHENTICATION_URL,
        {
            client_id, client_secret, grant_type, scopes
        },
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": 'origin'
            }
        }
    )
}

export const getProductFromKiotViet = ({ pageSize, includeInventory, categoryId }, { access_token, token_type }) => {
    return axios.get(KIOTVIET_PRODUCT_URL, {
        params: {
            pageSize,
            includeInventory,
            categoryId
        },
        headers: {
            "Authorization": `${token_type} ${access_token}`,
            "Retailer": "amac"
        }
    }
    )
}