import * as requestFromServer from "../app/modules/Auth/_redux/authCrud";
import { actions } from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  const axiosApiInstance = axios.create();
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url === process.env.REACT_APP_PUBLIC_URL + "token/refresh") {
        localStorage.clear();
        store.dispatch(actions.logout());
      } else if (error.response.status === 401 && !originalRequest._retry) {
        try{
          originalRequest._retry = true;
          const token = store.getState().auth.user.refreshToken;
          const getToken = await requestFromServer.refreshToken(token);
          const authToken = getToken.data.token;
          store.dispatch(actions.refreshToken(authToken));
          originalRequest.headers.Authorization = `Bearer ${authToken}`;
          return axiosApiInstance(originalRequest);
        }catch{
          localStorage.clear();
          store.dispatch(actions.logout());
        }
      }
      Promise.reject(error)
    }
  )
}
