/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { ACCOUNT_URL } from "../../../../_partials/constant/route";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const sayHiUserWithHours = () => {
    const hours = new Date().getHours()
    return (hours >= 4 && hours < 11) ? "Chào buổi sáng" :
      (hours >= 11 && hours < 14) ? "Chào buổi trưa nhé" :
        (hours >= 14 && hours < 18) ? "Chào buổi chiều nhé" :
          "Chào buổi tối nhé"
  }
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-light-primary btn-dropdown w-auto btn-clean d-flex align-items-center btn-lg px-2 "
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-md-inline mr-1">
            {sayHiUserWithHours()},
          </span>{" "}
          <span className="text-name font-weight-bolder font-size-base d-md-inline mr-1">
            {user.fullName}
          </span>
          <ExpandMoreIcon />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div className="navi navi-spacer-x-0 pt-5 mt-5">
          <Link to={ACCOUNT_URL.CHANGE_PASSWORD} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Đổi mật khẩu
                </div>
                <div className="text-muted">
                  Cập nhật mật khẩu mới
                  <span className="label label-light-danger label-inline font-weight-bold">
                    cập nhật
                  </span>
                </div>
              </div>
            </div>
          </Link>
          <Link to={ACCOUNT_URL.BASE_URL} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-user text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Thông tin tài khoản
                </div>
                <div className="text-muted">
                  Xem chi tiết thông tin tài khoản
                  <span className="label label-light-info label-inline font-weight-bold">
                    chi tiết
                  </span>
                </div>
              </div>
            </div>
          </Link>
          <div className="navi-separator mt-3"></div>
          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">Đăng xuất</Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
