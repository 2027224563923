import * as requestFromServer from './paymentCrud';
import { paymentSlice, callTypes } from './paymentSlice'
import { SnackbarSlice } from '../../snackbar-alert/redux/snackBarSlice'
const actionsSnackbar = SnackbarSlice.actions
const { actions } = paymentSlice;

export const fecthPayments = (params) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPaymentsList(params)
        .then(response => {
            const { status } = response
            if (status === 200) {
                const paymentEntities = response.data.data
                const totalCount = response.data.total
                dispatch(actions.paymentListFetched({ paymentEntities, totalCount }))
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy danh sách hoá đơn" }))
            }
        }
        ).catch(error => {
            dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy danh sách hoá đơn" }))
        })
}

export const fecthPayment = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getPayment(id)
        .then(response => {
            const { status } = response
            if (status === 200) {
                const payment = response.data;
                dispatch(actions.paymentFetched({ payment }))
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy hoá đơn" }))
            }
        }
        ).catch(error => {
            dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy hoá đơn" }))
        })
}