
export const MANAGE_ORDERS_URL = {
    BASE_URL: "/manage-order",
}
export const WELCOME_URL = {
    BASE_URL: "/welcome",
}
export const ORDERS_DETAIL_URL = "/manage-order/details"
export const OUTSTANDING_ORDERS_URL = {
    BASE_URL: "/outstanding-orders"
}
export const ACCOUNT_URL = {
    BASE_URL: "/account",
    CHANGE_PASSWORD: "/change-password",
    RESET_PASSWORD: "/reset-password",
}
export const RECIEPT_URL = {
    BASE_URL: "/reciept"
}
export const NOTIFICATION_URL = {
    BASE_URL: "/notification"
}
export const PRODUCT_URL = {
    BASE_URL: "/product"
}

export const URL_ERROR = 'error/error-v2'



