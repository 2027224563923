import React from 'react';
import { Grid } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
const NotificationOrder = () => {
    return <React.Fragment>
    <Grid className="w-100 mt-5 font">
        <table className="table table-hover my-table table-notificate">
            <tbody>
                <tr>
                    <td>11/12/2021</td>
                    <td><DescriptionIcon /></td>
                    <td>Đơn hàng #33333 đã được chuyển sang trạng thái chờ thu cọc</td>
                    <td className="green-primary font-weight-500 px-3">Đánh dấu đã đọc</td>
                    <td className="text-danger font-weight-500 px-3">Xóa</td>
                </tr>
                <tr>
                    <td>11/12/2021</td>
                    <td><DescriptionIcon /></td>
                    <td>Đơn hàng #33333 đã được chuyển sang trạng thái chờ thu cọc</td>
                    <td className="green-primary font-weight-500">Đánh dấu đã đọc</td>
                    <td className="text-danger font-weight-500">Xóa</td>
                </tr>
            </tbody>
        </table>
    </Grid>
</React.Fragment>
}
export default NotificationOrder;