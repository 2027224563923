import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { AntTab, AntTabs } from '../../../partials/Tab';
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { defaultInputRanges, defaultStaticRanges } from '../../../partials/dateRange/defaultRange';
import Pagination from '@material-ui/lab/Pagination'
import Select from 'react-select';
import { formatDate } from '../../../partials/formatDate';
import { useHistory } from 'react-router-dom';
import { ORDERS_DETAIL_URL } from '../../../../../../_metronic/_partials/constant/route';
import { BrowserView } from "react-device-detect";
import * as actions from "../../../_redux//order/orderAction"
import { useDispatch, useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { checkAssignment } from '../../../partials/assignments/checkAssignment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const options = [
    { value: 1, label: 'Ngày giao giảm dần' },
    { value: 0, label: 'Ngày đặt giảm dần' },
];
const BrowserViewComponent = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [filter, setFilter] = useState({})
    const [page, setPage] = useState(1)
    const [status, setStatus] = useState(null)
    const [code, setCode] = useState(null)
    const [tabValue, setTabValue] = useState("all")
    const [order, setOrder] = useState(null)
    const [search, setSearch] = useState(null)
    const [dateState, setDateState] = useState(
        {
            datefilter: {
                startDate: null,
                endDate: null,
                key: 'datefilter'
            },
        }
    );
    useMemo(() => setFilter({ ...filter, page, status, code, createdAtFrom: dateState.datefilter.startDate, createdAtTo: dateState.datefilter.endDate, order, search }),
        [page, status, code, dateState, order, search]
    )
    useEffect(() => {
        dispatch(actions.fetchOrder(filter))
    }, [filter, dispatch])
    const [dateRangeStatus, setDateRangeStatus] = useState()

    useEffect(() => {
        dispatch(actions.getStatisticsOrders())
    }, [dispatch])
    const { totalCount, orderEntities, statistics } = useSelector(
        (state) => ({
            totalCount: state.order.totalCount,
            orderEntities: state.order.orderEntities,
            statistics: state.order.statistics
        })
    )
    const goToDetailPage = (id) => {
        history.push(`${ORDERS_DETAIL_URL}/${id}`)
    }
    const calculateTotal = (value) => {
        return new Intl.NumberFormat().format(value) + " đ"
    }
    const checkTabsFilter = (val) => {
        if (val === 2) {
            setStatus(2)
            setCode(null)
        } else if (val === "all") {
            setCode(null)
            setStatus(null)
        }
        else {
            setCode(val)
            setStatus(null)
        }
        setPage(1)
        setTabValue(val)
    }

    const handleSearch = (e) => {
        let { value } = e.target
        if (value.length === 0) {
            setSearch(null)
        } else {
            setSearch(value.trim())
            setPage(1)
        }
    }
    const removeFormRange = () => {
        setDateRangeStatus(false)
        setDateState(
            {
                ...dateState, datefilter: {
                    startDate: null,
                    endDate: null,
                    key: 'datefilter'
                }
            }
        )
    }
    return <React.Fragment>
        {orderEntities && <BrowserView>
            <Grid container className='bg-white py-10 rounded'>
                <Grid container >
                    {statistics && <AntTabs className='custom-tabs' value={tabValue} onChange={(e, newValue) => checkTabsFilter(newValue)}>
                        <AntTab label={`Tất cả (${statistics.tat_ca})`} value={"all"} />
                        <AntTab label={`Lên đơn (${statistics.len_don})`} value={"len_don"} />
                        <AntTab label={`Đang sản xuất (${statistics.san_xuat})`} value={"san-xuat"} />
                        <AntTab label={`Đang giao (${statistics.giao_hang})`} value={"giao_hang"} />
                        <AntTab label={`Hoàn tất (${statistics.hoan_tat})`} value={"hoan_tat"} />
                        <AntTab label={`Đã hủy (${statistics.huy_don})`} value={2} />
                    </AntTabs>}
                </Grid>
                <BrowserView className='w-100'>
                    <Grid container className='mt-7 px-5 d-flex mr-2'>
                        <Grid item md={4} lg={3}>
                            <div className='position-relative w-100'>
                                <i className="fa fa-search icon position-absolute mt-3 ml-3" />
                                <DebounceInput
                                    className='my-input pl-10 w-100'
                                    placeholder='Nhập vào mã đơn, tên sản phẩm...'
                                    minLength={1}
                                    debounceTimeout={600}
                                    onChange={handleSearch} />
                            </div>
                        </Grid>
                        <Grid item md={4} lg={4} container className='d-flex justify-content-end'>
                            <span className='align-self-center font-weight-bolder mr-3'>Ngày đặt</span>
                            <Grid item md={8} className='position-relative'>
                                <Grid className='position-relative'>
                                    <DateRangeIcon className='position-absolute mt-2 ml-2 color-gray-dark cursor-pointer' onClick={() => setDateRangeStatus(!dateRangeStatus)} />
                                    <input className='my-input w-100 pl-10 letter-spacing cursor-pointer' onClick={() => setDateRangeStatus(!dateRangeStatus)}
                                        value={
                                            (dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ?
                                                formatDate(dateState.datefilter.startDate) + '-' + formatDate(dateState.datefilter.endDate) : ''}
                                        readOnly
                                    />
                                    {(dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ? <HighlightOffIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => removeFormRange()} /> : <ExpandMoreIcon className="position-absolute mt-2 clear-form cursor-pointer" style={{ right: "3%" }} onClick={() => setDateRangeStatus(!dateRangeStatus)} />}
                                </Grid>
                            </Grid>
                            {dateRangeStatus && <Grid item md={9} className='position-relative mt-3'>
                                <DateRangePicker
                                    onChange={item => {
                                        setPage(null)
                                        setDateState({ ...dateState, ...item })
                                    }
                                    }
                                    ranges={[dateState.datefilter]}
                                    locale={locales.vi}
                                    staticRanges={defaultStaticRanges}
                                    inputRanges={defaultInputRanges}
                                />
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Grid className="mt-7 d-flex justify-content-end  px-5" container>
                        <span className='align-self-center mr-3'>Sắp xếp theo</span>
                        <Grid item md={2}>
                            <Select
                                className="my-select2"
                                placeholder="Ngày giao giảm dần"
                                options={options}
                                onChange={option => setOrder(option.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="mt-7  px-5">
                        {(orderEntities.length !== 0) ? <table className="table table-hover my-table">
                            <thead>
                                <tr className="dark-gray">
                                    <th scope="col">Mã đơn</th>
                                    <th scope="col">Sản phẩm</th>
                                    <th scope="col">Trạng thái</th>
                                    <th scope="col">Tổng tiền</th>
                                    <th scope="col">Ngày đặt</th>
                                    <th scope="col">Ngày giao dự kiến</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderEntities.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row" className="py-5 pb-10 size-14 green-primary"><span className="cursor-pointer"
                                            onClick={() => goToDetailPage(item.id)}
                                        >{item.id}</span></th>
                                        <td className="py-5 pb-10 size-14">{item.name}</td>
                                        <td className="py-5 pb-10 size-14 font-weight-700">{(Object.keys(item.assignments).length > 0) ? checkAssignment(item.assignments) : (item.status === 1) ? <span className="text-primary">Hoàn tất</span> : <span className="text-danger">Hủy đơn</span>}</td>
                                        <td className="py-5 pb-10 size-14">
                                            {calculateTotal(item.orderTotalAmount)}
                                        </td>
                                        <td className="py-5 pb-10 size-14">{item.createdAt}</td>
                                        <td className="py-5 pb-10 size-14">{item.deliveredAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : <span className="w-100 text-center size-14">Không tìm thấy kết quả</span>}
                    </Grid>
                    <Grid className="px-5">
                        <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
                    </Grid>
                </BrowserView>
            </Grid>
        </BrowserView>}
    </React.Fragment>
}
export default BrowserViewComponent;