import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { login } from "../_redux/authCrud";
import { useHistory } from "react-router";
import { ACCOUNT_URL } from "../../../../_metronic/_partials/constant/route";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Wrong email format")
    .required("Tài khoản không được để trống"),
  password: Yup.string()
    .required("Mật khẩu không được để trống"),
});
function Login(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    document.title = 'Đăng nhập vào hệ thống Amacsport';
  }, []);
  return <React.Fragment>
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      enableReinitialize={true}
      validationSchema={LoginSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        enableLoading();
        setTimeout(() => {
          login(values.username, values.password)
            .then(({ data: { token } }) => {
              setStatus(false);
              disableLoading();
              props.login(token);
            })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
              setStatus({
                fail: 'Email hoặc mật khẩu không đúng',

              });
            });
        }, 1000);

      }}
    >
      {({ handleChange, status }) => (
        <Form>
          <div className="w-100 d-flex justify-content-center align-content-center mb-20">
            <div>
              <div className="w-100 logo-auth-style">
                <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-customer.jpg")} className="logo-login" />
              </div>

              <p className="size-24 font-weight-700 title-login mt-15">Đăng nhập vào hệ thống Amacsport</p>
              {(status && !loading) && <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible"><div class="alert-text font-weight-bold">{status.fail}</div></div>}
              <div className="form mt-10">
                <p className="title size-16">Email</p>
                <input className="input-login" name="username" onChange={handleChange} defaultValue={""}
                />

                <ErrorMessage name="username" component="div" className="mt-3 text-danger size-14" />
              </div>
              <div className="form mt-3">
                <p className="title size-16">Mật khẩu</p>
                <input className="input-login" type="password" name="password" defaultValue={""} onChange={handleChange}
                />
                <ErrorMessage name="password" component="div" className="mt-3 text-danger size-14" />
              </div>
              <div className="d-flex justify-content-between mt-7">
                <div className="size-14 d-flex">
                  <input type="radio" className="radio-login mr-3" />
                  <span style={{ lineHeight: "15px" }}>Nhớ mật khẩu</span>
                </div>
                <div>
                  <p className='title-forgot'
                    onClick={() => history.push(ACCOUNT_URL.RESET_PASSWORD)}
                  >Quên mật khẩu?</p>
                </div>
              </div>
              <div className='mt-5'>
                <button className="button-forgot size-16 font-weight-700 px-9 py-4 my-3"
                  type="submit"
                >
                  <span>Đăng nhập</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
            </div>
          </div>
        </Form>)}
    </Formik>
  </React.Fragment>
}
export default connect(null, auth.actions)(Login);
