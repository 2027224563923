import axios from "axios";

const ACCOUNT_URL = process.env.REACT_APP_PUBLIC_URL + "api/profile"
const PROVINCES_URL = process.env.REACT_APP_PUBLIC_URL + "api/provinces"
const CHANGE_PASSWORD_URL = process.env.REACT_APP_PUBLIC_URL + "api/password"

export function putProfile(payload) {
    return axios.put(ACCOUNT_URL, payload)
}
export function getProvinces() {
    return axios.get(PROVINCES_URL)
}
export function getDistricts(id) {
    return axios.get(`${PROVINCES_URL}/${id}`)
}
export function putChangePassword(payload) {
    return axios.put(CHANGE_PASSWORD_URL, payload)
}
