import * as requestFromServer from './orderCrud';
import { OrderSlice, callTypes } from './orderSlice'
import { SnackbarSlice } from '../../../snackbar-alert/redux/snackBarSlice'
const actionsSnackbar = SnackbarSlice.actions
const { actions } = OrderSlice;

export const fetchOrder = ({ page, status, code, createdAtFrom, createdAtTo, order, search }) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getOrdersList(({ page, status, code, createdAtFrom, createdAtTo, order, search }))
        .then(response => {
            const { status } = response
            if (status === 200) {
                const orderEntities = response.data.data
                const totalCount = response.data.total
                dispatch(actions.orderListFetched({ orderEntities, totalCount }))
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy danh sách danh mục" }))
            }
        }
        ).catch(error => {
            // dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy danh sách danh mục" }))
        })
}
export const getOrderByID = (id) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .getOrderByID((id))
        .then(response => {
            const { status } = response
            if (status === 200) {
                const orderForEdit = response.data
                dispatch(actions.orderFetched({ orderForEdit }))
            } else {
                dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy thông tin danh mục" }))
            }
        }
        ).catch(error => {
            dispatch(actionsSnackbar.alertFailure({ message: "Không thể lấy danh sách danh mục" }))
        })
}
export const getStatisticsOrders = () => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.getStatistics().then((response) => {
        const { status } = response;
        if (status === 200) {
            const statistics = response.data
            dispatch(actions.statisticFetched({ statistics }))
        }
    })
}