import axios from "axios";

const PAYMENT_URL = process.env.REACT_APP_PUBLIC_URL + "api/payments"

export function getPaymentsList(params) {
    return axios.get(PAYMENT_URL,{params});
    
}
export function getPayment(id) {
    return axios.get(PAYMENT_URL+"/"+id);
    
}