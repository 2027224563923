import _ from 'lodash';
import React from 'react';
export const calculateSizeFormat = (sizeObj) => {
    let size = []
    let count = []
    let results = []
    Object.keys(_.groupBy(sizeObj, 'size')).map((key) => {
        return size.push(key)
    })
    Object.values(_.groupBy(sizeObj, 'size')).map((val, index) => {
        let total = 0
        for (let i = 0; i < val.length; i++) {
            for (let j = 0; j < size.length; j++) {
                if (val[i].size === size[j]) {
                    total += parseFloat(val[i].quantity)
                }
            }
        }
        return count.push(total)
    })
    for (let i = 0; i < size.length; i++) {
        for (let j = 0; j < count.length; j++) {
            var obj = {}
            if (i === j) {
                obj['key'] = size[i]
                obj['value'] = count[j]
                results.push(obj)
            }
        }
    }
    return results.map((item, index) => {
        return <span key={index}> {item.key}: ({item.value}){(index === results.length - 1) ? "" : ", "}</span>
    })
}