export const checkAssignment = (value) => {
    if (value.length > 1 || Object.keys(value).length > 1) {
        return "Đang sản xuất"
    } else {
        const state = Object.values(value)[0].state
        let task = Object.values(value)[0].task
        const cus = Object.values(value)[0].task.slice(-3)
        if (cus === "CUS") {
            task = task.substring(0, task.length - 3)
        }
        return (task === "Ép vải" || task === "May" || task === "kiểm bán thành phẩm" || task === "kiểm thành phẩm" || task === "In") ? "Đang sản xuất" :
            (state === 0) ? "Chờ " + task : (state === 1) ? "Đang " + task : "Hoàn tất " + task
    }
}