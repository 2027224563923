import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import OrderTracking from './order-tracking/OrderTracking';
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import * as orderActions from "../ManageOrder/_redux/order/orderAction"

import { ModalConfirm } from '../../../_metronic/_partials/controls';
import SizeDetail from './size-detail/SizeDetail';
import { calculateSizeFormat, formatSizeOfSex } from './partials';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory } from 'react-router';

export const OrderDetail = ({ match: { params: { id }, }, }) => {
    const dispatch = useDispatch()
    const history = useHistory();

    const [showModalSize, setShowModalSize] = useState()
    useEffect(() => {
        dispatch(orderActions.getOrderByID(id))
    }, [id, dispatch])
    const { orderForEdit } = useSelector((state => ({
        orderForEdit: state.order.orderForEdit
    })))
    useEffect(() => {
        document.title = 'Thông tin đơn hàng'
    }, [])
    return <React.Fragment>
        <KeyboardBackspaceIcon 
          onClick={()=>{
            history.goBack()
        }}
         style={{ fontSize: 30, background: '#fff', marginBottom: 10, marginTop: 10,width:50,borderRadius:10 }} />
        {orderForEdit && orderForEdit.id == id ? <LightgalleryProvider>
            <Grid container className='bg-white rounded font-slab content-wrapper-detail'>
                <p className=' size-20 black-primary font-weight-900'>Thông tin đơn hàng</p>
                <Grid container className='dark-33 size-14'>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Mã đơn </span>
                        <span>{orderForEdit.id}</span>
                    </Grid>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Sản phẩm:</span>
                        <span>{orderForEdit.name}</span>
                    </Grid>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Chất liệu vải:</span>
                        <span>{orderForEdit.material && orderForEdit.material.join(',')}</span>
                    </Grid>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Sale phụ tách:</span>
                        <span className='sale'>{orderForEdit.seller ? orderForEdit.seller.name : ""}</span>
                    </Grid>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Danh sách size:</span>
                        <span>
                            {formatSizeOfSex(orderForEdit.size)}:
                            {calculateSizeFormat(orderForEdit.size)}
                        </span>
                        <span className="text-primary size-14 ml-3 cursor-pointer" onClick={() => setShowModalSize(!showModalSize)}>Chi tiết size</span>
                    </Grid>
                    <Grid container className='mb-5'>
                        <span className='mr-3'>Ngày giao dự kiến:</span>
                        <span>{orderForEdit.deliveredAt}</span>
                    </Grid>
                    <Grid className='mb-5 w-100 overflow-hidden'>
                        <p className='mb-5'>Hình ảnh</p>
                        <Grid className='light-gallery'>
                            {orderForEdit.gallery.map((item, index) => (
                                <React.Fragment>
                                    {item && <Grid item className={`item-${index + 1} one-item`} key={index}>
                                        <LightgalleryItem src={item} group={"goup-" + id}>
                                            <img alt={"img"} src={item} className="image-gallery" />
                                        </LightgalleryItem>
                                    </Grid>}
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <p className=' size-20 black-primary font-weight-900 mt-7'>Quy trình đơn hàng</p>
                    </Grid>
                    <Grid container>
                        {orderForEdit.assignments && <OrderTracking assignments={orderForEdit.assignments} />}
                    </Grid>
                </Grid>
            </Grid>
        </LightgalleryProvider> : ""}
        {orderForEdit && <ModalConfirm
            show={showModalSize}
            setVisible={setShowModalSize}
            title={"Chi tiết size"}
            size={"lg"}
            MyForm={
                <SizeDetail sizeData={orderForEdit.size} />
            }
        />}
    </React.Fragment>
}
