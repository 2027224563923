import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarSlice } from '../snackbar-alert/redux/snackBarSlice'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
export default function CustomizedSnackbars() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { actions } = SnackbarSlice
    const { type, message, status } = useSelector(
        (state) => ({
            message: state.snackbar.message,
            type: state.snackbar.type,
            status: state.snackbar.status
        })
    )
    const handleClose = () => {
        dispatch(actions.alertOff())
    };
    return type && message && status && (
        <div className={classes.root}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={status} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
