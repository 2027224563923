/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import { ACCOUNT_URL, WELCOME_URL } from "../../../../_metronic/_partials/constant/route";
import Login from './Login'
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import '../style.scss'
import ResetPassword from "./ResetPassword/ResetPassword";
import WelcomeToAmacSports from "../../Welcome/Welcome";
export function AuthPage() {
  return (
    <>
      <Switch>
        <ContentRoute path={WELCOME_URL.BASE_URL} component={WelcomeToAmacSports} />
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
          >
            {/*begin::Aside*/}
            <div
              className="d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 w-50 mobile-none"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-auth.jpg")})`,
              }}
            >
            </div>
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-lg-0 content-auth">
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path={ACCOUNT_URL.RESET_PASSWORD} component={ResetPassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </div>
              {/*end::Content body*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Login*/}
        </div>
      </Switch>
    </>
  );
}
