import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../_redux/paymentAction";
import { RECIEPT_URL } from '../../../../_metronic/_partials/constant/route';
import { useHistory } from 'react-router';
import './style.scss'
const PaymentList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    useEffect(() => {
        document.title = 'Danh sách hoá đơn';
        dispatch(actions.fecthPayments({ page }));
        console.log(page);
    }, [dispatch, page]);
    const { entities, totalCount } = useSelector((state) => ({
        entities: state.payment.paymentEntities,
        totalCount: state.payment.totalCount
    }));
    const calculateTotal = (value) => {
        return new Intl.NumberFormat().format(value) + " đ"
    }
    const methodPayment = (method) => {
        switch (method) {
            case 0:
                return 'Tiền mặt';
            case 1:
                return 'Thẻ';
            default:
                return 'Chuyển khoản';
        }
    }
    return <React.Fragment>
        <Grid container className='bg-white payment-container rounded font-roboto'>
            <Grid>
                <p className='size-20 dark-primary font-weight-900 font-slab'>Danh sách hóa đơn</p>
            </Grid>
            <Grid container className="mt-7">
                <table className="table table-hover my-table">
                    <thead>
                        <tr className="dark-gray">
                            <th scope="col">Mã</th>
                            <th scope="col">Phương thức thanh toán</th>
                            <th scope="col">Tổng tiền</th>
                            <th scope="col">Thời gian tạo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entities && entities.map((item, index) => (
                            <tr>
                                <th scope="row" className="py-5 pb-10 size-14 green-primary"><span className="cursor-pointer"
                                    onClick={() => {
                                        history.push(RECIEPT_URL.BASE_URL + "/" + item.id);
                                    }}
                                >{item.id}</span></th>
                                <td className="py-5 pb-10 size-14">{methodPayment(item.method)}</td>
                                <td className="py-5 pb-10 size-14">{calculateTotal(item.total)}</td>
                                <td className="py-5 pb-10 size-14">{item.createdAt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
            <Grid className='w-100 paginate-payment'>
                <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
            </Grid>
        </Grid>
    </React.Fragment>
}
export default PaymentList;