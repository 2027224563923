/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { MANAGE_ORDERS_URL, OUTSTANDING_ORDERS_URL, RECIEPT_URL, WELCOME_URL } from "../../../../_partials/constant/route";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListIcon from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HelpIcon from '@material-ui/icons//Help';
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* Quản lý đơn hàng */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(MANAGE_ORDERS_URL.BASE_URL, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={MANAGE_ORDERS_URL.BASE_URL}>
            <span className="svg-icon menu-icon">
              <ListIcon />
            </span>
            <span className="menu-text">Quản lý đơn hàng</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Đơn hàng chưa thanh toán */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(OUTSTANDING_ORDERS_URL.BASE_URL, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={OUTSTANDING_ORDERS_URL.BASE_URL}>
            <span className="svg-icon menu-icon">
              <AssignmentIcon />
            </span>
            <span className="menu-text">Đơn hàng chưa thanh toán</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Hóa đơn */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(RECIEPT_URL.BASE_URL, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={RECIEPT_URL.BASE_URL}>
            <span className="svg-icon menu-icon">
              <CreditCardIcon />
            </span>
            <span className="menu-text">Hóa đơn</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Sản phẩm */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(WELCOME_URL.BASE_URL, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={WELCOME_URL.BASE_URL}>
            <span className="svg-icon menu-icon">
              <HelpIcon />
            </span>
            <span className="menu-text">Hướng dẫn sử dụng</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
