import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { AntTab, AntTabs } from '../../../partials/Tab';
import SVG from "react-inlinesvg";
import { MobileView } from "react-device-detect";
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import * as actions from "../../../_redux/order/orderAction"
import { ModalConfirm } from '../../../../../../_metronic/_partials/controls';
import { ORDERS_DETAIL_URL } from '../../../../../../_metronic/_partials/constant/route';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { Button } from 'react-bootstrap';
import { formatDate } from '../../../partials/formatDate';
import { DateRangePicker } from 'react-date-range';
import DateRangeIcon from '@material-ui/icons/DateRange';
import * as locales from 'react-date-range/dist/locale';
import { defaultInputRanges, defaultStaticRanges } from '../../../partials/dateRange/defaultRange';
import Select from 'react-select';
import { checkAssignment } from '../../../partials/assignments/checkAssignment';
import Pagination from '@material-ui/lab/Pagination';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const options = [
    { value: 1, label: 'Ngày giao giảm dần' },
    { value: 0, label: 'Ngày đặt giảm dần' },
];
const MobileViewComponent = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [isShowFilter, setShowFilter] = useState(false)
    const [filter, setFilter] = useState({})
    const [page, setPage] = useState(1)
    const [status, setStatus] = useState(null)
    const [code, setCode] = useState(null)
    const [tabValue, setTabValue] = useState("all")
    const [order, setOrder] = useState(null)
    const [search, setSearch] = useState(null)
    const [dateRangeStatus, setDateRangeStatus] = useState()
    const [dateState, setDateState] = useState(
        {
            datefilter: {
                startDate: null,
                endDate: null,
                key: 'datefilter'
            },
        }
    );
    useMemo(() => setFilter({ ...filter, page, status, code, createdAtFrom: dateState.datefilter.startDate, createdAtTo: dateState.datefilter.endDate, order, search }),
        [page, status, code, dateState, order, search]
    )
    useEffect(() => {
        dispatch(actions.fetchOrder(filter))
    }, [filter, dispatch])

    useEffect(() => {
        dispatch(actions.getStatisticsOrders())
    }, [dispatch])
    const { totalCount, orderEntities, statistics } = useSelector(
        (state) => ({
            totalCount: state.order.totalCount,
            orderEntities: state.order.orderEntities,
            statistics: state.order.statistics
        })
    )
    const goToDetailPage = (id) => {
        history.push(`${ORDERS_DETAIL_URL}/${id}`)
    }
    const calculateTotal = (value) => {
        return new Intl.NumberFormat().format(value) + " đ"
    }
    const checkTabsFilter = (val) => {

        if (val === 2) {
            setStatus(2)
            setCode(null)
        } else if (val === "all") {
            setCode(null)
            setStatus(null)
        }
        else {
            setCode(val)
            setStatus(null)
        }
        setPage(1)
        setTabValue(val)
    }
    const handleSearch = (e) => {
        let { value } = e.target
        if (value.length === 0) {
            setSearch(null)
        } else {
            setSearch(value.trim())
            setPage(1)
        }
    }
    const removeFormRange = () => {
        setDateRangeStatus(false)
        setDateState(
            {
                ...dateState, datefilter: {
                    startDate: null,
                    endDate: null,
                    key: 'datefilter'
                }
            }
        )
    }
    return <React.Fragment>
        <MobileView>
            <Grid container className="bg-white mt-3">
                {statistics && <AntTabs className='custom-tabs mobile' value={tabValue} onChange={(e, newValue) => checkTabsFilter(newValue)}>
                    <AntTab label={`Tất cả (${statistics.tat_ca})`} value={"all"} />
                    <AntTab label={`Lên đơn (${statistics.len_don})`} value={"len_don"} />
                    <AntTab label={`Đang sản xuất (${statistics.san_xuat})`} value={"san-xuat"} />
                    <AntTab label={`Đang giao (${statistics.giao_hang})`} value={"giao_hang"} />
                    <AntTab label={`Hoàn tất (${statistics.hoan_tat})`} value={"hoan_tat"} />
                    <AntTab label={`Đã hủy (${statistics.huy_don})`} value={2} />
                </AntTabs>}
            </Grid>

            <Grid container className="d-flex justify-content-between mt-3 align-items-center">
                <Grid style={{ width: '85%' }}>
                    <DebounceInput
                        className='my-input pl-3 w-100'
                        placeholder='Nhập vào mã đơn, tên sản phẩm...'
                        minLength={1}
                        debounceTimeout={600}
                        onChange={handleSearch} />
                </Grid>
                <div className='p-2 rounded' style={{ backgroundColor: '#A5D3A5' }} onClick={() => setShowFilter(!isShowFilter)}><SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} /></div>
            </Grid>
            <Grid container>
                {orderEntities.length > 0 ? orderEntities.map((item, index) => (
                    <div className="w-100 p-2 box-item bg-white mt-5 rounded" key={index} onClick={() => goToDetailPage(item.id)}>
                        <div className="progess-status">{(Object.keys(item.assignments).length > 0) ? checkAssignment(item.assignments) : (item.status === 1) ? "Hoàn tất" : "Hủy đơn"}</div>
                        <div className="main-content w-100 d-flex justify-content-between">
                            <div className="left d-flex justify-content-center align-items-center mr-3"><img src={item.image} className="w-100" /></div>
                            <div className="right">
                                <p className='code'>#{item.id}</p>
                                <p className='m-0'>{item.name}</p>
                                <div className='time position-relative d-flex justify-content-between w-75 size-14 mt-1'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")} style={{ width: 17 }} />
                                        <span className='ml-2'>{item.createdAt}</span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <i className="flaticon-truck"></i>
                                        <span className='ml-2'>{item.deliveredAt}</span>
                                    </div>
                                </div>
                                <div className='total mt-1'>
                                    <span>Tổng tiền: </span>
                                    <span>{calculateTotal(item.orderTotalAmount)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <Grid className="w-100 p-5 bg-white rounded text-center size-14 mt-5">Không tìm thấy kết quả</Grid>
                }
            </Grid>
            <Grid className="w-100 mt-7 bg-white py-5 rounded d-flex justify-content-center">
                <Pagination count={Math.ceil(totalCount / 10)} page={page} onChange={(e, val) => setPage(val)} className='my-navigation' />
            </Grid>
            <ModalConfirm
                show={isShowFilter}
                setVisible={setShowFilter}
                title="Lọc danh sách đơn hàng"
                MyForm={
                    <React.Fragment>
                        <Grid container>
                            <p className='align-self-center font-weight-bolder mt-5'>Ngày đặt</p>
                            <Grid item xs={12} className='position-relative'>
                                <DateRangeIcon className='position-absolute mt-2 ml-2 color-gray-dark' onClick={() => setDateRangeStatus(!dateRangeStatus)} />
                                <input className='my-input w-100 pl-10 letter-spacing' onClick={() => setDateRangeStatus(!dateRangeStatus)}
                                    value={
                                        (dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ?
                                            formatDate(dateState.datefilter.startDate) + '-' + formatDate(dateState.datefilter.endDate) : ''}
                                    readOnly
                                />
                                {(dateState.datefilter.startDate !== null && dateState.datefilter.endDate !== null) ? <HighlightOffIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => removeFormRange()} /> : <ExpandMoreIcon className="position-absolute mt-2 clear-form" style={{ right: "3%" }} onClick={() => setDateRangeStatus(!dateRangeStatus)} />}
                            </Grid>
                            {dateRangeStatus && <Grid item xs={12} md={9} className='position-relative mt-3'>
                                <DateRangePicker
                                    className='date-range-mobile'
                                    onChange={item => {
                                        setPage(null)
                                        setDateState({ ...dateState, ...item })
                                    }
                                    }
                                    ranges={[dateState.datefilter]}
                                    locale={locales.vi}
                                    staticRanges={defaultStaticRanges}
                                    inputRanges={defaultInputRanges}
                                />
                            </Grid>}
                        </Grid>
                        <Grid className="w-100">
                            <p className='align-self-center font-weight-bolder mt-5'>Lọc theo ngày</p>
                            <Select
                                className="my-select2"
                                placeholder="Ngày giao giảm dần"
                                options={options}
                                onChange={option => setOrder(option.value)}
                            />
                        </Grid>
                        <Grid className="w-100 d-flex justify-content-center mt-7">
                            <Button variant="primary" onClick={() => setShowFilter(!isShowFilter)}>Lọc</Button>
                        </Grid>
                    </React.Fragment>
                }
            />
        </MobileView>
    </React.Fragment>
}
export default MobileViewComponent;