import React, { useEffect } from 'react';
import { OrderLoading } from './loading/OrderLoading';
import './style.scss'
import BrowserViewComponent from './view-case/BrowserView';
import MobileViewComponent from './view-case/MobileView';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const ManageOrder = () => {
    useEffect(() => {
        document.title = 'Quản lý đơn hàng'
    },[])
    return <React.Fragment>
        <OrderLoading/>
        <BrowserViewComponent />
        <MobileViewComponent />
    </React.Fragment>
}
export default ManageOrder