import React from 'react';

const SizeDetail = ({ sizeData }) => {
    return <React.Fragment>
        <table className="table table-hover my-table">
            <thead>
                <tr className="dark-gray">
                    <th scope="col">STT</th>
                    <th scope="col">Size</th>
                    <th scope="col">Giới tính</th>
                    <th scope="col">Số áo</th>
                    <th scope="col">Tên trên</th>
                    <th scope="col">Tên dưới</th>
                    <th scope="col">Số lượng</th>
                    <th scope="col">Ghi chú</th>
                </tr>
            </thead>
            <tbody>
                {sizeData.map((item, index) => (
                    <tr key={index} className="size-14">
                        <th scope="row" className="dark-blue">{index + 1}</th>
                        <td className="">{item.size}</td>
                        <td className="">{item.sex}</td>
                        <td className="">{item.number}</td>
                        <td className="">{item.name_above}</td>
                        <td className="">{item.name_below}</td>
                        <td className="">{item.quantity}</td>
                        <td className="">{item.note}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </React.Fragment>
}

export default SizeDetail;